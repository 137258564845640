@import url("../../node_modules/bootstrap/dist/css/bootstrap.css");
@import url("./login.css");
@import url("./signup.css");

.sidebar-header {
  background: #dde4eb;
}

/* .MuiTableCell-head {
  background-color: #faf4d4 !important;
} */

.modal-content.loader {
  background-color: transparent;
  border: 0;
}

div.create-btn {
  background: #dde4eb;
  color: #fff;
  font-weight: 300;
  font-size: 14px;
  border-radius: 6px;
  padding: 10px 15px;
  cursor: pointer;
}

.quill-container {
  max-height: 400px; /* Set the maximum height here */
  overflow-y: auto; /* Add vertical scrollbar if content exceeds the maximum height */
}

.MuiPaper-rounded {
  border-radius: "40px" !important;
}

.bg-weather {
  background-image: url("../assets/images/bg_weather.jpeg");
  height: 100vh !important;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.glass-effect {
  background: radial-gradient(
    ellipse at 17.48% 90.89%,
    rgba(5, 5, 6, 0.6) 0%,
    rgba(60, 60, 64, 0.6) 100%
  );

  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(5.1px);
  border: 1px solid rgba(137, 137, 137, 0.5);
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  border-radius: 8px;
  background-color: #e7e7e7;
  border: 1px solid #cacaca;
}

::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #858484;
}

.weather-event::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 0px;
}

/* src/ColoredTable.css */
.colored-table {
  width: 100%;
  border-collapse: collapse;
}

.colored-table th,
.colored-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.colored-table th {
  padding-top: 12px;
  padding-bottom: 12px;
  color: white;
}

.header-red {
  background-color: red;
}

.header-green {
  background-color: green;
}

.header-blue {
  background-color: blue;
}
