.loginLogo{
    background-color: #B9D6F1;
    height: 100vh;
}
.divImg >img{
    margin-top: 50%;
}
/* .loginForm{} */

.formStyle{
    margin-top: 25%;
    padding: 2rem;
    
}